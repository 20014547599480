var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { color: "transparent", tile: "", elevation: "0" } },
    [
      _c(
        "v-row",
        {
          staticClass: "my-8",
          staticStyle: { "min-height": "350px" },
          attrs: { justify: "center", "no-gutters": "", align: "center" },
        },
        [
          _c("v-col", { attrs: { cols: "11", lg: "9" } }, [
            _c("div", { staticClass: "text-center" }, [
              _c("p", { staticClass: "text-body-1" }, [
                _vm._v(_vm._s(_vm.$t("label.processingYourOrder"))),
              ]),
              _c("p", { staticClass: "text-body-1" }, [
                _vm._v(_vm._s(_vm.$t("label.pleaseDontRefreshPage"))),
              ]),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }